<template>
  <div id="app">
    <div class="container">
      <!-- 公司列表 -->
      <div class="left">
        <el-input placeholder="输入关键字进行搜索..." prefix-icon="el-icon-search" v-model="filterText"
          style="margin: 10px 10px 5px 0; width: 100%">
        </el-input>
        <div class="left-content-top">
          <div class="type-title">
            <span class="title">单位列表</span>
          </div>
          <dv-decoration-1 style="width:300px;height:15px;" :color="['lightblue']" />
          <el-tree :check-strictly="true" :data="companyList" :props="defaultProps" :default-expand-all="true"
            node-key="id" :filter-node-method="filterNode" :expand-on-click-node="false" show-checkbox
            @check="getPickCompant" ref="tree">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :title="node.label">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>

      <!-- 用户信息 -->
      <div class="right">
        <div>
          <el-row class="elrow">
            <el-col :span="6" class="rows">
              <el-date-picker v-model="time" type="datetimerange" @change="pickedTimes" start-placeholder="开始日期"
                end-placeholder="结束日期" clearable :default-time="['12:00:00']">
              </el-date-picker>
            </el-col>
            <el-col :span="6" class="rows">
              <el-button type="primary" plain size="small" style="margin-left:10px" @click="inquiry">查询</el-button>
            </el-col>
            <el-col :span="6" class="rows" style="float:right;width:13%;margin: 0">
              <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
                <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>

        <div class="content">
          <div class="type-title">
            <span class="title">访客信息</span>
            <dv-decoration-3 style="width:260px;height:10px;" />
          </div>
          <el-table :data="visitorsList" :max-height="700" style="width: 100%;cursor:pointer" ref="table"
            highlight-current-row>
            <el-table-column fixed="left" type="index" label="序号" width="60" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('访客姓名')" prop="name" label="访客姓名" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('访客手机号')" prop="phone" label="访客手机号" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('审核人')" prop="wechatAdminName" label="审核人" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('到访公司')" prop="ciId" label="到访公司" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('身份')" prop="humanIdentity" label="身份" align="center">
              <template slot-scope="scope">
                  {{ scope.row.humanIdentity == "0"?"访客": scope.row.humanIdentity == "1"?"施工人员":"其他人员" }}
              </template>
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('车牌号')" prop="carNum" label="车牌号" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('通过次数')" prop="throughNumber" label="通过次数" align="center">
            </el-table-column>
            <el-table-column v-if="tableTitle.includes('时间')" prop="createTime" label="时间" align="center">
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click="details(scope.row)">人脸识别照片</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-pagination :background="true" @current-change="pagination" :current-page.sync="current" :page-size="limit"
        layout="total, prev, pager, next , jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CompanyInfo from "@/api/managementApi/CompanyInfo";
import companySystem from "@/api/managementApi/CompanyInfo";
import BaseData from '@/assets/config/BaseData'

// 记录接口
import VisitorsToRecord from '@/api/facilitiesManagement/VisitorsToRecord'

export default {
  name: 'VisitorsToRecord',
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '访客姓名',
      }, {
        id: 1,
        title: '访客手机号',
      }, {
        id: 4,
        title: '车牌号',
      }, {
        id: 5,
        title: '到访公司',
      }, {
        id: 6,
        title: '通过次数',
      }, {
        id: 7,
        title: '时间',
      }, {
        id: 8,
        title: '审核人',
      }, {
        id: 9,
        title: '身份',
      }],
      tableTitle: ['访客姓名', '访客手机号', '车牌号', '到访公司', '通过次数', '时间','审核人','身份'],
      dialogVisible: false,
      dialogTitle: '',
      // 公司列表
      companyList: [],
      // 过滤查询
      filterText: '',
      // 公司树形结构绑定
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
      // 选择的公司
      checkedKeys: [],
      cwechatAdminIdiIds: '',
      startTime: '',
      endTime: '',
      // 访客信息列表
      visitorsList: [],
      total: 0,
      limit: 10,
      current: 1,
      allCompanyList: [],
      time: '',
      healthCode: '',
      healthCodeList: [],
      tourCode: '',
      tourCodeList: [],
    }
  },
  created() {
    this.getAllCompany()
    this.init()
  },
  mounted() {
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    init() {
      this.getCompanyStructure()
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('访客姓名')
      } else {
        this.tableTitle = value
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 选择公司过滤器
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 获取公司节点信息   (根据公司查全部设备)
    getPickCompant(value) {
      this.checkedKeys = this.$refs.tree.getCheckedKeys();
      this.getWechatVisitorsHistory();
    },
    // 分页
    pagination(params) {
      this.getWechatVisitorsHistory()
    },
    // 时间选择
    pickedTimes(value) {
      if (value == null) {
        this.startTime = '';
        this.endTime = '';
      } else {
        let startTime = this.$moment(value[0]).format('YYYY-MM-DD HH:mm:ss')
        let endTime = this.$moment(value[1]).format('YYYY-MM-DD HH:mm:ss')
        this.startTime = startTime;
        this.endTime = endTime;
      }
    },
    // 查询
    inquiry() {
      this.getWechatVisitorsHistory()
    },
    // 详情
    details(info) {
      this.facePicture = BaseData.baseUrl + info.facePhoto
      let a = document.createElement('a');
      a.href = this.facePicture;
      a.target = "_blank";
      a.click();
    },
    // 查所有公司结构
    async getCompanyStructure() {
      const { data: res } = await CompanyInfo.getAllCompanyStructure()
      this.companyList = res.data.list;
    },
    // 访客记录
    async getWechatVisitorsHistory() {
      const { data: res } = await VisitorsToRecord.getWechatCheckLog(this.current, this.limit, this.checkedKeys, this.cwechatAdminIdiIds, this.startTime, this.endTime)
      console.log(this.res);
      this.visitorsList = res.data.list;
      for (let i = 0; i < this.visitorsList.length; i++) {
        if (this.visitorsList[i].status == '1') {
          this.visitorsList[i].status = '审核通过'
        } else if (this.visitorsList[i].status == '0') {
          this.visitorsList[i].status = '待审核'
        } else if (this.visitorsList[i].carNum == '') {
          this.visitorsList[i].carNum = '无'
        } else {
          this.visitorsList[i].status = '审核未通过'
        }
        this.visitorsList[i].rejectInformation == null ? this.visitorsList[i].rejectInformation = '无' : this.visitorsList[i].rejectInformation
      }
      this.total = res.data.total
      this.processInfo()
    },
    // 所有公司
    async getAllCompany() {
      const { data: res } = await companySystem.getCompanyInfo()
      this.allCompanyList = res.data.list
      this.getWechatVisitorsHistory();

    },
    processInfo() {
      for (const item of this.visitorsList) {
        if (item.region == null) {
          item.region = '—'
        }
        if (item.carNum == null) {
          item.carNum = '无'
        }
        for (let i = 0; i < this.allCompanyList.length; i++) {
          if (item.ciId == this.allCompanyList[i].id) {
            item.ciId = this.allCompanyList[i].name
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
// UI默认样式
@import "../../assets/css/uiCss.scss";

.elrow {
  margin: 10px 0;
}

.container {
  box-sizing: border-box;
  width: 100%;
  height: 89vh;
  display: flex;

  .left {
    width: 20%;
    height: 95%;
    box-sizing: border-box;
    margin: 0 15px;

    .left-content-top {
      border-radius: 10px;
      width: 100%;
      height: 98%;
      overflow: scroll;
      background: rgba(1, 65, 70, 0.2);
      scrollbar-width: none;
    }
  }

  .type-title {
    margin: 10px;

    .title {
      font-family: "宋体";
      font-size: 20px;
      font-weight: 600;
      color: #0cc7ce;
      letter-spacing: 2px;
    }

    .title::before {
      content: "丨";
      font-weight: 600;
      font-size: 20px;
    }
  }

  .right {
    height: 80%;
    width: 100%;

    .content {
      position: relative;
      box-sizing: border-box;
      padding: 0px 10px 0 0;
      margin: 10px 0;
      border-radius: 10px;
      background: rgba(1, 65, 70, 0.2);
    }
  }
}

.dialog {
  height: 300px;
  overflow: scroll;
  display: flex;
}
// 表格和树形图hover

/deep/.el-table__row:hover {
  color: #72d4dc;
  }
/deep/.el-table__body .el-table__row.hover-row td{
  background-color:rgba($color: #007a91, $alpha: 0.5) !important;
}

/deep/.el-tree-node__content:hover{
background-color:rgba($color:#007a91, $alpha: 0.5) !important;
color: #72d4dc;
}
</style>